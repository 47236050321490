#header {
    position: fixed;
    z-index: 50;
    top: 0;
    width: 100%;
    min-height: 129px;
    background: $color4;
    border-bottom: 50px solid #1a96d1;

    .homepage & {
        border-bottom: 1px solid $color8;
    }

    .logo {
        display: inline-block;
        position: relative;
        z-index: 40;
        float: left;
        padding: 20px 0;

        img {
            max-width: 210px; //400px;
        }
    }

    .search {
        position: absolute;
        right: 15px;
        top: 0;
        z-index: 1;

        form {
            background: #e63a45;
            color: $color4;
            float: left;

            label {
                display: none;
            }

            input {
                background: inherit;
                border: 0px;
                border-radius: 0px;
                color: inherit;
                width: 200px;
                box-shadow: none;
                height: 44px;
                line-height: 44px;
                font-size: $font-size;
            }

            button {
                background: $color2;
                border: 0px;
                border-radius: 0px;
                color: inherit;
                padding: 0 15px;
                height: 44px;
                line-height: 44px;
                font-size: 14px;
            }
        }

        .secured-buttons {
            float: right;

            a {
                line-height: 44px;
                padding: 0 15px;
                display: block;
                float: left;
                font-size: $font-size-medium;

                &.dashboard {
                    background: #1a96d1;
                    color: #fff;
                    padding: 0 30px;
                }

                &.logout {
                    background: $color3;
                    color: #fff;
                }
            }
        }
    }

    //
    // Via JS is te zien dat de search balk op mobiel wordt verplaatst naar mobilenav
    //
    #mobilenav .search {
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        padding: 15px 15px 0 15px;

        form {
            background: $color4;
            color: $color1;

            input {
                position: relative;
                z-index: 10;
                width: 100%;
                padding-right: 45px;
            }

            button {
                position: absolute;
                z-index: 11;
                right: 15px;
                top: 15px;
                background: $color4;
                padding: 0 16px;
            }
        }
    }


}

#header-spacing {
	height: 179px;

	.homepage & {
		height: 129px;
	}
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (max-width: $screen-md-min) {
    #header {
        min-height: 118px;

        .logo {
            padding: 30px 0 20px;

            img {
                max-width: 160px; //300px;
            }
        }
    }

	#header-spacing {
		height: 168px;

		.homepage & {
			height: 118px;
		}
	}

}

// ***************************************************************** 
// XS
// ***************************************************************** 
@media (max-width: $screen-sm-min) {
    #header {
        min-height: 81px;

        .logo {
            padding: 16px 0;

            img {
                max-width: 110px; //230px;
            }
        }

        .search {
            form {
                float: none;

                .form-control {
                    &::-webkit-input-placeholder {
                        color: #f5bec4;
                    }
                }
            }

            .secured-buttons {
                float: none;
                border-bottom: 1px solid #1a96d1;
                margin-bottom: 5px;

                a.dashboard,
                a.logout {
                    float: none;
                    margin: 0;
                    padding: 0;
                    background: none;
                    font-size: $font-size;
                    position: relative;

                    span {
                        margin-right: 30px;
                    }

                    .fa {
                        position: absolute;
                        right: 0;
                        top: 12px;
                    }
                }

                a.logout {
                    margin-bottom: 10px;
                }


            }
        }
    }

	#header-spacing {
		height: 131px;

		.homepage & {
			height: 81px;
		}
	}

}

@media (min-width: $screen-lg-min) {
    #header {
        .search {
            form {
                background: none;

                .form-group {
                    input {
                        background: #e63a45;
                        width: 0;
                        padding: 0;
                        transition: all 300ms ease;

                        &.active {
                            width: 250px;
                            padding: 0 15px;
                        }
                    }
                }

                .form-group,
                button {
                    float: left;
                }
            }
        }
    }
}