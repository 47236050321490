#content .banner,
#content-blocks .banner{
	position:relative;
	//margin-bottom:30px;
	border:1px solid #dfdfdf;
	min-height:350px;
	overflow:hidden;
	a{ 
		h2{
			position:absolute;
			z-index:21;
			width:100%;
			left:0;
			top:0;
			padding:15px 60px 0 15px;

			img{
				position:absolute;
				right:15px;
				top:15px; 
				height:40px;
				border:none;
			}
		}
		figure{
			position:relative;
			z-index:20;
			img{
				border:none; 
				max-width:auto;
				width:100%;
			}
		}
	}
}
#content #aside .horizontal{
	min-height:0px;
	margin-bottom:20px;
	a {  
		h2{
			//top:50%;
			//transform: translate(0, -50%); 
			padding:20px 15px 20px 15px;
			margin:0;
			line-height:1em;
			font-size:28px;
			img{ 
				left:15px;
				right:auto;
				height:48px;
				top:50%;
				transform: translate(0, -50%); 
			}
		}
	}
}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (min-width: $screen-lg-min) {
	#content #aside .horizontal{ 
		a {  
			h2{
				top:50%;
				transform: translate(0, -50%); 
				padding-left:30px;
			}
		}
	}
}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (max-width: $screen-md-min) {
	#content #aside .horizontal{ 
		a {
			h2{
				font-size:$font-size;
				// padding-left:70px;
				img{  
					height:40px;
				}
			}
		}
		
	}
}

// ***************************************************************** 
// XS
// ***************************************************************** 
@media (max-width: $screen-sm-min) {
	.banner{
		a{
			h2{
				font-size:$font-size;
			}
		}
	}
}
