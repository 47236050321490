#news-show{
	dl.row{
		padding:0 0 15px 0;
		dt{
			margin:0;
			padding:3px 15px;
			font-weight:700;
		}
		dd{
			margin:0;
			padding:3px 15px;
		}
	}

	#content{
		
		#aside{
			figure{
				padding-top:30px;
				img{
					width:100%; 
					border:1px solid $color6;
				}
			}

			ul{
				padding-top:30px;
			}
		}
	}
}