.office{
	.form-horizontal{
		.control-label{
			text-align:left;
			font-size:$font-size;
		}
	}
	.btn-primary{
		padding:10px 20px;
		font-size:$font-size-medium;
	}
}