@import url('https://fonts.googleapis.com/css?family=Raleway:400,700,800');

$font: 'Raleway', sans-serif;

$color1: #704f4f;	// Default text color -> grey
$color2: #e00916;	// Default heading color -> red
$color3: #24a5de; 	// Background footer -> Blue
$color4: #ffffff;	// Background body -> White
$color5: #4eb7e4;	// Border -> Line bottom header
$color6: #b6b6b6;	// Breadcrumbs -> grey
$color7: #4eb7e4; 	// Background footer -> Light blue
$color8: #dfdfdf; // border line light grey

$font-size:20px;
$font-size-medium:16px; 
$font-size-small: 14px;

$border-radius: 5px;