#cumarine-interacties{
	#content{
		#curamines-search{ 
			.row:first-of-type{
				margin:0;
				padding:0;
				div[class^="col-"]{
					padding:0;
					margin:0;
				}
			}
			ul#alphabet{
				background:$color3;
				margin:0;
				padding:10px 30px; 
				min-height:60px;
				li{
					display:inline-block;
					margin:0;
					padding:10px 2.5px 9px 2.5px;
					line-height:normal;
					a{
						display:block;
						color:$color4;
						border-bottom:1px solid $color3;
						font-weight:800;
						font-size:$font-size-medium;
						&:hover,
						&.active{
							border-bottom:1px solid $color4;
							color:$color4;
						} 
					}
					&:after,
					&:before{
						display:none;
					}
				}
			}
			form{
				position:realtive;
				background:$color7;
				label{
					display:none;
				}
				input{
					background:inherit;
					height: 60px;
					line-height:60px;
					padding:0 75px 0 15px;
					font-family:$font;
				    color: $color4; 
					background:$color7;
				    font-size:$font-size;
				    border: none;
				    border-radius: 0;
				    box-shadow: none;
				}
				button{
					position: absolute;
				    right: 0px;
				    top: 0;
				    width: 60px;
				    height: 60px;
				    padding: 0;
					background: $color3;
				    border: none;
				    border-radius: 0;
				    box-shadow: none;
				    color: $color4; 
				    font-size: 16px;
				}
			}
		}

		ul#curamines{
			margin:0;
			padding:30px;
			background:#e9f6fc;
			li{ 
				margin:0;
				padding:15px 85px 15px 15px;
				position:relative;
				a{
					border:none;
					color:$color1;
					display:block;
					span.download{
						position:absolute;
						right:15px;
						top:15px;
						color:$color3;
					}
				}
				&:before,
				&:after{
					display:none;
				}
				&:nth-child(odd){
					background:$color4;
				} 
				&:hover{
					background:$color2;
					a, a span.download{ 
						color:$color4;
					}
				}
			}
		}

		.disclaimer{
			border-top:2px solid $color4;
			padding:30px;
			background:#e9f6fc;
			margin:0 0 15px 0;
			h3,p,ul, ul li{
				font-size:$font-size-small;
				line-height:2em;
				margin:0;
				padding:0;
			} 
			ul{
				li{
					padding-left:20px;
					&:before{
						color:$color1;
						font-size:8px;
					}
				}
			}
		}

		#aside{ 
			text-align:right;
			ul{
				display:inline-block;
				li{
					padding:0;
					margin:0 0 10px 0;
					background:$color2;  
					text-align:left;
					a{
						padding:15px;
						color:$color4;
						border:none;
						display:block;
					}
					&:before,
					&:after{
						display:none;
					}
					&:hover{
						background:#e84750;
					}
				}
			}
		}
	}
}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (max-width: $screen-md-min) {
	 #cumarine-interacties #content{
	 	#aside ul li{
			font-size:$font-size-small;
	 	} 
	}

}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (max-width: $screen-md-min) {
	 #cumarine-interacties #content{
	 	#aside{
			text-align:left;
	 	}
	 	#curamines-search ul#alphabet{
	 		border-bottom:2px solid $color4;
		}
	}

}
