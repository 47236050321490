.contact-form{

	&.applicatie {
		form {
			.form-group {
				label {
					width: 30%;
				}
				div {
					width: 70%;
					
					select {
						height: 47px;
					}
					.radio {
						width: 50%;
						float: left;
						margin-top: 0;

						label {
							width: 100%;

							input {
								width: 10%;
								display: inline-block;
							}
						}
					}
				}

				&.custom {
					.radio, .checkbox {
						margin-top: 0;
						label {
							width: 100%;
							input {
								width: 20px;
								display: inline-block;
							}
						}
					}
				}

				&:last-of-type{
			    	div{
			    		margin-left: 30%; 
			    	}
			    }
			}

			h2 {
				margin-top: 20px;
			}

			.g-recaptcha {
				margin-left: 30%;
			}
		}
	}
	 
	form{
		.form-group {
			label{
				float:left;
				width:25%;
				padding:10px 0;
				font-weight:400;
			}
			div{
				float:left;
				width: 75%;
				input,textarea,select{
					position: relative;
					z-index:20;
					display:inline-block;
				    border: 1px solid $color5;
				    border-radius: 4px;
				    display: block;
				    font-size: 18px;
				    margin:0 auto;
				    padding: 10px 20px;
				    width:100%;
				    height:auto;
				    font-family: $font;
				    font-weight:400;
				    border-radius:$border-radius;
				}

				 textarea{
					height: 150px;
				}	
			}	
			 
			.g-recaptcha{
				display:inline-block;
				position:relative;
				z-index:40;
			}
			input[type="submit"] {
				background:$color3;
				color:$color4;
			    float: none;
			    margin: 0;
			    padding: 15px 0;
			    width: 250px;
			    text-transform: uppercase;
			    font-family: $font;
			    border:none;
			}

			&:after {
		        content: "";
		        display: table;
		        clear: both;
		    }
		    &:last-of-type{
		    	div{
		    		margin-left:25%; 
		    	}
		    }

		    .g-recaptcha:last-of-type{
		    	div{
		    		margin-left:0px; 
		    	}
		    }
		}
		.help-block{
			position: relative;
			z-index:21;
			border-bottom-right-radius: $border-radius;
			border-bottom-left-radius: $border-radius;
			background:#f2dede;
			color:#a94442;
		    width: 100%;
		    margin:-$border-radius 0 0 0 ;
		    padding:10px;
		    font-size: $font-size-small;
		    z-index: -1;
		}
		.captcha{
			.help-block{
				position:relative;
				z-index:30;
				width:302px; 
				margin-left:25%; 
			}
		} 
	}
}

//
// MAPS 
//
#gmap_wrapper {
    width: 100%;
    height: 400px; 
	border:1px solid $color5; 
	margin-bottom:30px;
}


// ***************************************************************** 
// MD
// ***************************************************************** 
@media (max-width: $screen-lg-min) {
	.contact-form{
		form{
			.form-group {
				label{
					float:none;
					width:100%; 
					padding:5px 0;
				}
				div{
					float:none;
					width: 100%;
					input,textarea,select{
						padding:5px 20px;
					}
				}
			    &:last-of-type{
			    	div{
			    		margin-left:0; 
			    	}
			    }
			}
			.captcha{ 
				.help-block{ 
					margin-top:-10px;
					margin-left:0px; 
				}
			} 
		}
	}
}