#offices{
	#content{
		#map-holder {
			position: relative;

			.overlay {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background: #eee;
				z-index: 2;

				.text {
					position: absolute;
					top: 50%;
					width: 100%;
					text-align: center;
					transform: translateY(-50%);

					i {
						display: block;
						color: $color3;
						font-size: 50px;
						margin-bottom: 20px;
					}

					&.no-results,
					&.error {
						display: none;

						.form {
							width: 400px;
							margin: 0 auto;
							text-align: center;

							input,button{
								box-shadow: none;
								border:none;
								border-bottom: 2px solid $color3;
							}
							input {
								position:relative;
								z-index:10;
								width:100%;
								border-radius:0px;
								background:$color4;
								color:$color3;
								font-family: $font;
								font-size:$font-size;
								display: inline-block;
								height: auto;
								width: 60%;
								border-right: 1px solid $color3;
								&::-webkit-input-placeholder {
									color:$color5;
								}
							}
							select {
								appearance: none;
								color: $color3;
								box-shadow: none;
								border: none;
								border-radius: 0;
								border-bottom: 2px solid $color3;
								font-size: 20px;
								height: auto;
								width: 30%;
							}
							button {
								position: relative;
								z-index:11;	
								cursor:pointer;
								font-family: $font;
								font-size:$font-size;
								background:$color3;
								color:$color4;
								width: 10%;
								height: 42px;

								#loader {
									position: absolute;
									left: 8px;
									top: 8px;
									height: 25px;
									width: 25px;
									line-height: 25px;
									text-align: center;
									background: $color3;
								}
							}

							input,
							select,
							button {
								display: inline-block;
								float: left;
							}
						}
					}

					&.error {
						.form {
							width: 300px;

							input {
								width: 80%;
							}
							button {
								width: 20%;
							}
						}
					}
				}
			}

			#aside{
				position: absolute;
				left: 10px;
				top: 10px;
				z-index: 1;
				padding: 0;
				height: calc(100% - 40px);
	
				#search{
					position:relative;
					display:block;
					input,button{
						box-shadow: none;
						border:none;
						border-bottom: 2px solid $color3;
					}
					input{
						position:relative;
						z-index:10;
						width:100%;
						border-radius:0px;
						background:$color4;
						color:$color3;
						padding:15px 70px 15px 15px;
						font-family: $font;
						font-size:$font-size;
						&::-webkit-input-placeholder {
							color:$color5;
						}
					}
					button{
						position:absolute;
						z-index:11;	
						right:0;
						top:0;
						cursor:pointer;
						padding:15px;
						font-family: $font;
						font-size:$font-size;
						background:$color3;
						color:$color4;

						#loader {
							position: absolute;
							left: 10px;
    						top: 12px;
							height: 30px;
							width: 30px;
							line-height: 30px;
							text-align: center;
							background: $color3;
						}
					}
				}
	
				#my-location{
					display:block;
					cursor:pointer;
					color:$color3;
					padding:0 15px;
					height:52px;
					line-height:52px;
					background: #fff;
					.fa{
						margin-left:15px; 
						color:$color2;
					}
					&:hover .fa{
						color:$color3;
					}
				}
	
				#diensten-lijst{
					position: relative;
					margin-top: 10px;
					max-height:calc(100% - 116px);
					overflow-y: scroll;
					background: #fff;
					
					.dienst{
						border-top:1px solid #dfdfdf;
						padding:15px;
						cursor:pointer;

						h3, p {
							font-size: 12px !important;
							line-height: 16px;
							margin: 0;
						}
						
						h3 span{
							color:$color2;
						}
						p{
							font-size:$font-size-medium;
							&.tel,
							&.email,
							&.geaccrediteerd{
								padding-top:10px;
							}
							&.geaccrediteerd{
								font-size:$font-size-medium;
							}
						}
						ul{
							padding:15px 0 0 0;
							margin:0;
							li{
								font-size:$font-size-medium;
							}
						}
						&:first-of-type{
							border:none;
						}
						&:hover{
							background:#f9f9f9;
						}
					}
				}
			}
	
			#map{
				height:80vh;
				width:100%;
				.infobubble{ 
					img{
						width:30px;
						height:auto;
					}
					.inner{
						position:relative;
						padding:30px;
						h2,h3,p{
							font-size:$font-size-small;
							margin:0;
							padding:0;
						}
						h2{
							font-size:$font-size-medium;
							margin-bottom:5px;
							padding-right:15px;
						} 
						h3 span{
							color:$color2;
						}
						p.tel,p.email,p.geaccrediteerd{
							padding-top:10px;
						} 
					}
				}
			}
		}
	}
}

// ***************************************************************** 
// XS
// ***************************************************************** 
@media (max-width: $screen-sm-min) {
	#offices #content #aside #overviewItems{
		height:auto;
	}
	#offices #content #aside #overviewItems .item{
		cursor: default;
		&:hover{
			background:none;
		}
	}

}

@media (min-width: $screen-sm-min) {
	#offices {
		#content {
			.inner {
				#aside {
					width: 300px;
				}
			}
		}
	}
}