.offices{
	.btn{
		padding:10px 20px;
		font-size:$font-size-medium;
		background:$color3;
		color:$color4;
		border:none;
		.fa{
			margin-right:5px; 
		}
		&:hover{
			background:$color1;
		}
	}
	.offices-overview{
		.row{
			font-size:$font-size;
			line-height:22px;
			padding-top:10px;
			padding-bottom:10px;
			&:nth-child(odd){
				background:#F5F5F5;
			}
			&:hover{
				background:$color3;
				color:$color4;
				a{
					color:$color4;
				}
			}
			a{
				margin-left:10px;
			}
		}
	}
}