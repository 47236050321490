#aside{
	padding-top:15px;
	
	&.contact{
		h2{
			font-size:48px;
		}
		p{
			font-size:$font-size-medium;
		}
	}
}

// ***************************************************************** 
// XS
// ***************************************************************** 
@media (max-width: $screen-sm-min) {
	#aside{
		padding-top:60px;
	}
}