#page-search{
	#content 
	.search-block{
		padding:15px 0;

		article{
			padding:0 0 15px 0;
			margin:0 0 30px 0;
			border-bottom:1px solid #dfdfdf;
			h3{
				font-weight:bold;
			}
			p{
				font-weight:normal;
			}
			ul{
				li{

				}
			}
			&:last-of-type{
				margin-bottom:0px;
				border-bottom:none;
			}
		}
	}
}