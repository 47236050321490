#content {
    &.updates {
        table {
            width: 100%;
            font-size: 18px;

            tr {
                &:nth-child(even) {
                    background: #f5f5f5;
                }

                td,
                th {
                    text-align: left;
                    padding: 10px 10px;

                    &:first-child {
                        width: 10%;
                    }
                    &:nth-child(2) {
                        width: 75%;
                    }
                    &:nth-child(3) {
                        width: 10%;
                    }
                    &:last-child {
                        width: 5%;
                        text-align: center;

                        a {
                            color: $color7;
                        }

                        .info {
                            position: relative;

                            .fa {
                                color: $color7;
                                cursor: pointer;
                            }

                            .pop-up {
                                position: absolute;
                                top: 0;
                                right: 100%;
                                max-width: calc(100vw - 30px);
                                padding: 15px;
                                border-radius: 5px;
                                background: #fff;
                                min-width: 300px;
                                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                                text-align: left;
                                transition: all 300ms ease;

                                visibility: hidden;
                                opacity: 0;
                            }

                            &:hover {
                                .pop-up {
                                    visibility: visible;
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    transition: all 300ms ease;

                    &.clickable {
                        cursor: pointer;
                    }
                    &:hover {
                        background: #e3e3e3;
                    }
                }
            }
        }
    }
}