nav{
	padding:33px 0 0 0;
	float:right;
	ul#menu{
		list-style: none;
		margin:0;
		padding:0;
		li{
			position:relative;
			white-space: nowrap;
			display:inline-block;
			font-size:$font-size;
			color:$color1;
			margin:0;
			padding:30px 0;
			a{
				color:inherit;
				padding:0 15px;
				&.active,
				&:hover{
					color:$color2;
					text-decoration: none;
				}
				&:last-of-type{
					padding-right:0px;
				}
			}
			ul{
				display:none;
				position:absolute;
				left:15px;
				top:70px;
				border:1px solid $color8;
				margin:0;
				padding:0;
				background:$color4;
				li{
					display:block;
					padding:5px 10px 5px 5px;
					font-size:$font-size-medium;
					a{
						padding:0 5px;
					}
				}
			}
			&:hover ul{
				display:block;
			}
		}
	}
}

//
// MOBILE NAV
//
#mobilenav{
	position:absolute;
	z-index:30;
	width:100%;
	right:0;
	top:18px;
	.slicknav_menu{
		background:none;
		padding:0;
		.slicknav_btn{
			background:$color3;  
			height: 45px;
		    width: 45px;
		    margin: 0 15px 0 0;
		    border-radius:0px;
            .slicknav_icon {
            	height:11px;
            	padding:5px 3px;
                .slicknav_icon-bar {
                   width: 20px;
                   height: 3px;
                   margin: 3.5px 0; 
                   box-shadow:none;
                   background:$color4;
                }
            }
		}
		ul.slicknav_nav{
			background:$color3; 
			padding:0 0 15px 0;
			margin:0;
			overflow:scroll;
			max-height:calc(100vh - 63px);
			li{
				font-size:$font-size;
				font-family: $font;
				padding:5px 0;
				&:hover{
					a{
						background:none;
						color:$color4; 
					}
					ul li a{
						background:none;
					}
				}
				a.active,
				&:hover a:hover{
					color:$color1;
				}
				ul {
					padding:0;
					margin:0 0 0 15px;
					li{
						margin:0; 
						a{
							color:$color4;
						}
					}
				}
			}
		}
	}
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (max-width: $screen-lg-min) {
	nav{  
		ul#menu{ 
			li{ 
				a{
					padding:0 10px;
				}
				ul{
					left:10px;
				}
			}
		}
	}
}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (max-width: $screen-md-min) {
	nav{ 
		padding-top:28px;
		ul#menu{ 
			li, li ul li{ 
				font-size:14px;
			}
			li ul li{
				padding:0px 10px 0px 5px;
			} 
		}
	}
}

