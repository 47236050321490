#events-show{
	#content{
		.date{
			float:left;
			color:$color4;
			background:$color5;
			text-align: center;
			width:50px; 
			padding:10px 0;
			margin:12px 20px 10px 0;
			font-size:12px;
			line-height:1.1em;
		}
		#aside{
			figure{
				padding-top:30px;
				img{
					width:100%; 
					border:1px solid $color6;
				}
			}

			ul{
				padding-top:30px;
			}
		}
	}
}