#footer-bubbles{
	img{
		max-width:280px;
	}
	svg{ 
		max-width:280px;
		margin-bottom:-8px;
	} 
}

#footer{ 
	background:$color3;

	.row{
		&:nth-of-type(1){
			padding-top:35px;
			padding-bottom:35px;
		}
		&:nth-of-type(2){
			padding-top:0;
			padding-bottom:0;
		}
		&:nth-of-type(3){
			padding-top:30px;
			padding-bottom:30px;
		}

	}
	

	#footer-menu,
	#footer-address{
		list-style:none;
		margin:0;
		padding:0;
		li{
			display:inline-block;
			color:$color4;
			font-size:$font-size-medium;
			padding-right:30px;
			a{
				color:inherit;
				&:hover{
					color:$color5;
				}
			}
			&:last-of-type{
				padding-right:0px;
			}
		}
	}

	#footer-address{ 
		li{
			padding-right:15px;
			font-style:normal;
			a{
				color:$color2;
			}
			&:first-of-type{
				display:block;
			}
		}
	}

	#footer-links{
		margin:0 0 0 -2px;
		padding:0;
		li{
			font-size:14px;
			color:$color4;
			display:inline-block;
			margin-left:5px;
			a{
				color:inherit;
				&:hover{
					color:$color5;
				}
			}
			&:before{
				content:'|';
				margin-right:5px;
			}
			&:first-of-type{
				margin-left:0px;
				&:before{
					content:'';  
				}
			}
		}
	}

}

// ***************************************************************** 
// XS
// ***************************************************************** 
@media (max-width: $screen-sm-min) {
	#footer{ 
		.text-right{
			text-align: left;
		}
		#footer-address{
			margin-bottom:30px;
			li{
				display:block;
			}
		} 
	}
}
