#page-trombosediensten{

	#partial-searchtrombosediensten{
		h2,#mijn-locatie{
			display:none;
		} 
	}

	.trombosedienst{
		padding:15px;
		h2{
			margin:0 0 5px 0;
			padding:0;
			font-size:$font-size;
		}
		h3{
			margin:0 0 5px 0;
			padding:0;
			font-size:$font-size;
			font-weight:400;
		}
		h4{
			margin:0 0 10px 0;
			padding:0;
			font-size:$font-size;
			font-weight:400;
		}
		p{
			margin:0 0 5px 0;
			padding:0;
			&.geaccrediteerd{
				font-style: italic;
			}
		}
		&:nth-child(odd){
			background:#f9f9f9; 
		}
	}

	.list {
		strong {
			font-size: 18px;
		}
	}
}