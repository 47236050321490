
*{
    text-decoration:none;
    outline: none !important;
}

body {  
    font-family: $font;
    font-size: $font-size;
    font-weight:400;
    line-height: 1.5em;
    color:$color1;
	background:$color4;
	
    .container-fluid {
        margin: 0  auto;
        min-width: 320px;
        max-width: 1440px; 
    }
}

p{
    font-family: inherit;
    font-size:inherit;
    line-height:1.4em;
    margin:0 0 15px 0;
    padding:0;
}

a{
    color:$color3;
    text-decoration: none;
    transition:all 0.5s ease-in-out 0s;
    &:hover, &:focus { 
        text-decoration: none;
        color:$color1;
    }
}

h1,h2,h3,h4,h5,h6{ 
    font-family: inherit;
    line-height: 1.4em;
    color:$color2;
    margin:0 0 15px 0;
    padding:0; 
}
    h1{
        font-size:48px;
        font-weight:800;
    }
    h2{
        font-size:30px;
        font-weight:800;

    } 
    h3,h4,h5,h6{
        font-size:inherit; 
        margin-bottom: 0px;
        color:$color1;
    }

input,select,button{
    font-family: inherit;
    font-size:inherit;
    line-height: normal;
    color:$color1;
    margin:0;
    padding:0;
}


//
// CLASSES
//
.text-right{
    text-align:right;
}
.text-left{
    text-align:left;
}
.text-center{
    text-align:center;
}
.line{
    height:1px;
    border-bottom:1px solid $color5; 
}   

a.button,
input.button,
button.button{
    display:inline-block; 
    padding:15px 30px;
    font-size:$font-size;
    border:none;
    &:hover{ 
        border:none;
    }

    &.btn-red{ 
        background:$color2;
        color:$color4; 
        &:hover{
            color:$color5; 
        }
    }

    &.btn-blue{ 
        background:$color3;
        color:$color4; 
        &:hover{
            color:$color6; 
        }
    }
    
    &.btn-white{ 
        background:$color4;
        color:$color3; 
        &:hover{
            color:$color1; 
        }
    }
}

#location-error{
    display:none;
    line-height:1.4em;
    background:#d9534f;
    color:$color4;
    font-size:$font-size; 
    padding:15px;
    margin-bottom:15px;
}

 #toTop{
    display:none;
    position:fixed;
    right:15px;
    bottom:25px;
    border-radius:50%;
    background:$color2;
    height:45px;
    width:45px;
    line-height:40px;
    text-align:center;
    cursor:pointer;
    font-size:35px;
    font-weight:bold;
    z-index:99;
    border:1px solid $color4;
    @include transition(.5s ease-in-out);
    .fa{
        color:$color4;
    }
    &:hover{
        background:$color4;
        border:1px solid $color3;
        .fa{
            color:$color3;
        }
    }
}

// ***************************************************************** 
// XS
// ***************************************************************** 
@media (max-width: $screen-sm-min) {

    body {   
        font-size: $font-size-small; 
    }
 
    h1{
        font-size:24px; 
    }
    h2{
        font-size:18px; 

    } 
    a.button{ 
        font-size:$font-size-medium;
    }
}