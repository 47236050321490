.carousel {
    position: relative;
    z-index: 1;

    .slick-list {
        position: relative;
        z-index: 20;
        overflow: hidden;

        .slide {
            position: relative;
            z-index: 21;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            padding: 0;
            width: 100%;
            min-height: 400px;
            height: 30vh;
        }

        .slick-slide {
            position: relative;

            .title {
                position: absolute;
                z-index: 22;

                &.top-left {
                    top: 0;
                    left: 0;
                    text-align: left;
                }

                &.top-center {
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    text-align: center;
                }

                &.top-right {
                    top: 0;
                    right: 0;
                    text-align: right;
                }

                &.middle-left {
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    text-align: left;
                }

                &.middle-center {
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    text-align: center;
                }

                &.middle-right {
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: right;
                }

                &.bottom-left {
                    left: 0;
                    bottom: 0;
                    text-align: left;

                }

                &.bottom-center {
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%);
                    text-align: center;
                }

                &.bottom-right {
                    bottom: 0;
                    right: 0;
                    text-align: right;
                }

                h2, p {
                    text-align: inherit;

                    &.black {
                        color: #000;
                    }

                    &.grey-white {
                        color: #fff;
                    }
                }

                h2 {
                    font-size: 50px;
                    font-family: $font;
                    font-weight: 700;
                    margin: 0;
                    padding: 0;
                    line-height: normal;
                }

                p {
                    font-size: 20px;
                    margin: 0;
                    padding: 15px 0 0 0;
                    line-height: normal;
                }

                a {
                    text-decoration: none;

                    h2, p {
                        &.black {
                            color: #000;
                        }

                        &.grey-white {
                            color: #fff;
                        }
                    }
                }
            }

        }

    }

    button {
        position: absolute;
        z-index: 21;
        top: 50%;
        margin-top: -10px;
        background: none;
        border: none;
        color: #fff;
        font-size: 80px;

        &.slick-prev {
            left: 15px;
        }

        &.slick-next {
            right: 15px;
        }
    }

    .slick-dots {
        position: absolute;
        z-index: 21;
        bottom: 15px;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0;

        li {
            list-style: none;
            display: inline-block;
            background: $color3;
            width: 12px;
            height: 12px;
            line-height: 12px;
            cursor: pointer;
            margin: 0 5px;
            border-radius: 50%;
            border: 1px solid $color4;

            &.slick-active {
                background: $color4;
            }

            button {
                display: none;
            }

            button::before {
                content: " ";
            }
        }
    }


    &.page {
        background: $color3;
        text-align: center;
        padding: 30px 0;

        h2 {
            color: #fff;
        }
    }
}

.carousel.homepage-carousel {
    .slick-list {
        .slide {
            min-height: 500px;
            height: 50vh;
        }

        .title {
            padding: 60px 100px;
            background: $color2;

            h2 {
                font-size: 60px;
                font-weight: 800;
            }

            p {
                font-size: 30px;
                font-weight: 400;
                max-width: 550px;
            }
        }
    }
}


// ***************************************************************** 
// MD
// ***************************************************************** 
@media (max-width: $screen-lg-min) {
    .carousel.homepage-carousel {
        .slick-list {
            .title {
                padding: 40px;

                h2 {
                    font-size: 40px;
                }

                p {
                    font-size: 20px;
                    max-width: 400px;
                }
            }
        }
    }
}


// ***************************************************************** 
// XS
// ***************************************************************** 
@media (max-width: $screen-md-min) {
    .carousel .slick-list .slide {
        min-height: 0px;
        max-height: 300px;
    }
    .carousel.homepage-carousel .slick-list {
        .title {
            display: none;
        }

        .slide {
            min-height: 0px;
            max-height: 200px;
        }
    }
}