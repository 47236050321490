#page-secured #content{
	
	#aside{
		ul{
			li{
				background:$color2;
				margin:0 0 5px 0;
				padding:5px 10px;
				font-size:$font-size-medium;
				a{
					color:$color4;
					border:0px;
					display:block;
				}
				&:first-of-type{
					margin-bottom:15px;
				}
				&:hover{
					background:#e63a45;
				}
				&.active{ 
					background:$color3;
				}
				&:before,
				&:after{
					display:none;
				}
			}
		}
	}

}

// ***************************************************************** 
// XS
// ***************************************************************** 
@media (max-width: $screen-sm-min) {
	#page-secured #content{
		#aside{
			ul{
				li{ 
					padding:15px 10px;  
				}
			}
		}
	}
}