#breadcrumbs{
	ol{
		list-style:none;
		margin:0;
		padding:30px 0;
		li{
			margin:0;
			padding:0;
			font-size:12px;
			color:$color6;
			display:inline-block;
			a{
				color:inherit;
				margin:0 2px 0 0;
				padding:0;
				&:hover{
					color:$color3;
					text-decoration: none;
				}
			} 
			&:after{
				content:'/';
				margin-left:3px;
			}
		}

		span:last-of-type li:after{
			content:'';
			margin-left:0px; 
		}

	}

}

// ***************************************************************** 
// XS
// ***************************************************************** 
@media (max-width: $screen-sm-min) {
	#breadcrumbs ol { 
	    padding: 15px 0;
	}
}
