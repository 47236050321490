#nascholing {
    h1 {
        font-size: 40px;
    }
    small.form-small {
        display: block;
        margin-left: 30%;
        width: 70%;
        margin-bottom: 20px;
    }
    .form-group {
        margin-bottom: 0;
        margin-top: 15px;

        div {
            label {
                margin-bottom: 0;
            }
            .checkbox {
                width: 100%;

                label {
                    width: 100%;

                    input {
                        width: initial;
                        margin: 7px 10px 0 0;
                        float: left;
                    }
                }
            }
        }
    }
}