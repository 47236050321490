#news-index{
	article.news{
		margin-bottom:30px;
		padding-bottom:30px;
		border-bottom:1px solid $color6;
		h2{
			margin:0;
			padding:0;
		}
		h3{
			margin:0;
			padding:0;
			font-weight:400; 
		}
		p{
			margin:0 0 5px 0; 
			padding:0;
			color:$color6;
			font-size:$font-size-medium;
			font-style: italic;
		}
	}
}