#events-index{
	article.events{
		margin-bottom:30px;
		padding-bottom:30px;
		border-bottom:1px solid $color6;
		h2{
			margin:0 0 15px 0;
			padding:0;
		}
		h3{
			margin:0;
			padding:0;
			font-weight:400; 
		}
		.date{
			float:left;
			color:$color4;
			background:$color5;
			text-align: center;
			width:50px; 
			padding:10px 0;
			margin:5px 20px 10px 0;
			font-size:12px;
			line-height:1.1em;
		}
		p{
			margin:0 0 5px 0; 
			padding:0;
			color:$color6;
			font-size:$font-size-medium;
			font-style: italic;
		}
	}
}