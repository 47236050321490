#homepage {
    padding-top: 40px;
    #trombosedienst-zoeken {
        margin-bottom: 60px;
        .item {
            background: $color3;
            color: $color4;
            line-height: 60px;
            #mijn-locatie,
            #mijn-locatie-mobiel {
                padding: 0 30px;
                line-height: inherit;
                background: #1a96d1;
                text-align: center;
                cursor: pointer;
                &:after {
                    font-family: fontawesome;
                    content: '\f041';
                    margin-left: 10px;
                }
            }
            #mijn-locatie-mobiel {
                position: absolute;
                left: 0;
                top: 0;
                padding: 0;
                width: 38px;
                text-align: center;
                display: block;
                background: $color5;
                &:after {
                    margin-left: 0px;
                    font-size: 14px;
                }
            }
            #zoek-locatie {
                line-height: inherit;
                background: $color3;
                font-weight: 800;
            }
            #form-locatie {
                form {
                    label {
                        display: none;
                    }
                    .form-group {
                        margin: 0;
                        input {
                            width: 100%;
                            background: $color7;
                            height: 60px;
                            line-height: inherit;
                            border-radius: 0px;
                            border: none;
                            padding: 0 70px 0 15px;
                            color: $color4;
                            font-size: $font-size;
                            box-shadow: none;
                        }
                    }
                    button {
                        position: absolute;
                        top: 0;
                        right: 15px;
                        border: none;
                        padding: 0;
                        width: 60px;
                        height: 60px;
                        font-family: fontawesome;
                        content: '\f002';
                        font-size: $font-size-medium;
                        color: $color4;
                        background: #1a96d1;
                        border-radius: 0px;
                        box-shadow: none;
                    }
                }
            }
        }
    }
    #content {
        margin-bottom: 60px;
        img {
            border: none;
        }
        #agenda {
            margin-top: 20px;
            border: none;
            text-align: left;
            .agenda {
                .date {
                    float: left;
                    background: $color8;
                    color: inherit;
                    text-align: center;
                    width: 50px;
                    padding: 10px 0;
                    margin: 5px 20px 10px 0;
                    font-size: 12px;
                    line-height: 1.1em;
                    color: #000;
                }
                h3 {
                    color: $color2;
                }
            }
        }
    }
    #content-blocks {
        .row{
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
        }
        .item {
            height: auto;
            margin-bottom: 30px;
            > div{
                height: 100%;
            }
            .banner {
                &.img-banner{
                    >div {
                        display: flex;
                        flex-direction: column;
                        .top{
                            padding: 15px 15px 0 15px;
                            h2{
                                position: relative;
                                margin-bottom: 0;
                            }
                        }
                        .bottom{
                            padding: 15px;
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            .img-link{
                                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                                border: 1px solid #DFDFDF;
                                position: relative;
                                display: inline-block;
                                i{
                                    position: absolute;
                                    top: 50%;
                                    right: 20px;
                                    transform: translateY(-50%);
                                }
                                &:first-of-type{
                                    background-color: #d72b22;
                                    i{
                                        color: white;
                                    }
                                }
                                &:last-of-type{
                                    img{
                                        padding: 10px;
                                    }
                                }
                                img{
                                    width: 80%;
                                }
                            }
                        }
                    }
                }
                figure {
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    .img-responsive {
                        max-width: auto;
                        width: 100%;
                    }
                }
                &#nieuws,
                &#agenda {
                    padding: 15px 30px 60px 30px;
                    border: none;
                    h2 {
                        text-align: center;
                        position: relative;
                        margin-bottom: 30px;
                    }
                    p.text-center {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        button {}
                    }
                }
                &#nieuws {
                    background: $color2;
                    color: $color5;
                    h2 {
                        margin-bottom: 0;
                        color: $color4;
                        a {
                            color: $color4;
                        }
                    }
                    .news {
                        font-size: 17px;
                        line-height: 24px;
                        padding: 15px 0;
                        h3,
                        p {
                            color: $color4;
                            margin: 0;
                            padding: 0;
                            a {
                                color: inherit;
                                border-bottom: 1px solid $color4;
                            }
                        }
                        &:last-of-type {
                            margin-bottom: 45px;
                        }
                    }
                }
                &#agenda {
                    padding: 15px 30px;
                    background: $color4;
                    h2 {
                        color: $color3;
                        margin-bottom: 0;
                    }
                    .agenda {
                        font-size: 17px;
                        line-height: 24px;
                        padding: 15px 0;
                        border-bottom: 1px solid #dfdfdf;
                        a {
                            color: $color1;
                            .date {
                                float: left;
                                background: #e3e3e3;
                                color: inherit;
                                text-align: center;
                                width: 50px;
                                padding: 10px 0;
                                margin: 5px 20px 10px 0;
                                font-size: 12px;
                                line-height: 1.1em;
                            }
                            h3 {
                                color: $color2;
                            }
                            h3,
                            p {
                                margin: 0;
                                padding: 0;
                                a {
                                    color: inherit;
                                    border-bottom: 1px solid $color4;
                                }
                            }
                            &:after {
                                content: "";
                                display: table;
                                clear: both;
                            }
                        }
                        &:hover {
                            background: #f9f9f9;
                        }
                        &:last-of-type {
                            margin-bottom: 45px;
                        }
                    }
                }
                &#updates {
                    position: relative;
                    padding: 15px;
                    border: none;
                    background: #4eb7e4;
                    h2 {
                        text-align: center;
                        a {
                            color: #fff;
                        }
                    }
                    .update {
                        position: relative;
                        padding-bottom: 15px;
                        margin-bottom: 15px;
                        color: #fff;
                        overflow: hidden;
                        font-size: 17px;
                        line-height: 24px;
                        .date {
                            float: left;
                            background: #fff;
                            color: inherit;
                            text-align: center;
                            width: 50px;
                            padding: 10px 0;
                            margin: 5px 20px 10px 0;
                            font-size: 12px;
                            line-height: 1.1em;
                            color: #4eb7e4;
                        }
                        &:after {
                            content: ' ';
                            position: absolute;
                            height: 1px;
                            width: calc(100% - 30px);
                            left: 15px;
                            bottom: 0;
                            background: #fff;
                        }
                        &:last-of-type {
                            padding-bottom: 0;
                            margin-bottom: 0;
                            &:after {
                                display: none;
                            }
                        }
                    }
                    .button {
                        position: absolute;
                        bottom: 15px;
                        left: 50%;
                        transform: translateX(-50%);
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #homepage{
        #content-blocks {
            .row {
                flex-direction: row;
            }
        }
    }
}



// ***************************************************************** 
// LG
// ***************************************************************** 
@media (min-width: $screen-lg-min) {
    #homepage #content-blocks .item #agenda.banner .agenda {
        margin: 0 -30px;
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (max-width: $screen-lg-min) {
    #homepage {
        #trombosedienst-zoeken {
            .container-fluid {
                padding: 0;
            }
            .row:first-of-type {
                margin: 0;
            }
            div[class^='col-'] {
                padding-left: 0;
                padding-right: 0;
            }
            .item {
                #form-locatie form button {
                    right: 0px;
                }
                #zoek-locatie {
                    padding: 0 30px;
                }
            }
        }
        #content-blocks .item {
            #agenda.banner {
                padding-left: 0;
                padding-right: 0;
            }
            .banner {
                a h2 {
                    //@include alpha-background-color(rgba($color4, 0.9), $color4);
                    background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0) 100%);
                    /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0) 100%);
                    /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9) 50%, rgba(255, 255, 255, 0) 100%);
                    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    // filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=0);
                    /* IE6-9 */
                    padding: 15px 60px 45px 15px;
                }
            }
        }
    }
}

// ***************************************************************** 
// ONLY MD
// ***************************************************************** 
@media (min-width: $screen-sm-min) AND (max-width: $screen-lg-min) {
    #homepage #content-blocks .banner {
        min-height: 400px;
    }
}

// ***************************************************************** 
// XS
// ***************************************************************** 
@media (max-width: $screen-sm-min) {
    #homepage {
        #trombosedienst-zoeken {
            margin-bottom: 30px;
            .item {
                #zoek-locatie {
                    text-align: center;
                    padding: 25px 0;
                    line-height: 1.1em;
                }
                #form-locatie form .form-group input {
                    padding: 0 70px 0 50px;
                    font-size: 16px;
                    border-radius: 0px;
                }
            }
        }
        #content-blocks .item {
            #nieuws.banner {
                min-height: auto;
                h2 {
                    font-size: $font-size;
                }
            }
            #nieuws.banner,
            #agenda.banner {
                h2 {
                    margin-bottom: 15px;
                }
                .news:last-of-type,
                .agenda:last-of-type {
                    margin-bottom: 15px;
                }
            }
        }
    }
}