#content{
	position:relative;
	z-index:10; 
	p, 
	li{
		a{
			border-bottom:1px solid $color4;
			&:hover{
				border-bottom:1px solid $color3;
			}
		}
	}

	ul{
		list-style:none;
		margin:0 0 15px 0;
		padding:0;
		li{
			position: relative;
			padding:0 0 0 25px;
			&:before{
				position:absolute;
				left:0;
				top:1px;
				font-family: fontawesome;
				content:'\f111';
				color:$color2;
				font-size:12px;
			} 
			&.file:before{  
				content:'\f1c1'; 
			}
			&.source:before{  
				content:'\f0ac'; 
			}
			&.back:before{  
				content:'\f104'; 
			} 
			&.distance:before{ 
				left:6px; 
				content:'\f041'; 
			}
			&.route:before{ 
				left:4px; 
				content:'\f278'; 
			}
			&.calendar:before{
				content:'\f073';
			}
		}
	}

	ol{
		padding:0 0 0 20px;
		margin:0 0 15px 0;
		li{
			padding-left:5px;
		}
	}

	img{
		max-width:100%;
		height:auto;
		// border:1px solid $color5;
	}
	
	.list{
		.row{
			padding-top:5px;
			padding-bottom:5px;
			&:nth-child(even) {
				background:#F5F5F5;
			}
		}
	}

	td {
		vertical-align: top;
	}
}

// ***************************************************************** 
// XS
// ***************************************************************** 
@media (max-width: $screen-sm-min) {
	#content{
		padding-bottom:60px;
	}
}

