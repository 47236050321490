#partial-searchtrombosediensten{
	text-align: left;
	h2{
		text-align:inherit;
		margin-bottom: 15px;
    	padding: 15px 0;
	}
	.item{
			text-align: left;
			line-height:60px;
			position:relative;
			color:$color4;
			#mijn-locatie{
				padding:0 20px;
				line-height:inherit;
				background:#1a96d1;
				text-align:center;
				cursor:pointer;
				display:inline-block;
				&:before{
					font-family: fontawesome;
					content:'\f041';
					margin-right:10px; 
				}
			} 

			#form-locatie{
				form{
					margin-bottom: 15px;
					label{
						display:none;
					} 
					.form-group{
						margin:0;
						input{
							width:100%;
							background:$color7;
							height:60px;
							line-height:inherit;
							border-radius:0px;
							border:none;
							padding:0 70px 0 15px;
							color:$color4;
							font-size:$font-size;
							box-shadow: none; 
						}
					}

					button{
						position:absolute;
						top:0;
						right:0px;
						border:none;
						padding:0;
						width:60px;
						height:60px;
						font-family: fontawesome;
						content:'\f002';
						font-size:$font-size-medium;
						color:$color4;
						background:#1a96d1;
						border-radius:0px;
						box-shadow: none;
					}
				}
			}

		}
}

// ***************************************************************** 
// XS
// ***************************************************************** 
@media (max-width: $screen-sm-min) {
	#partial-searchtrombosediensten{
		.item{   
			#form-locatie form .form-group input{ 
				font-size:16px;
			}
		}
	} 
}