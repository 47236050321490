.friends{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $color2;
  padding: 30px;
  text-align: center;
  background-image: url('/img/ballen-footer.svg');
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: bottom right;

  img{
    margin-bottom: 10px;
  }

  p{
    color: white;
  }

  .btn{
    font-weight: bold;
  }
}