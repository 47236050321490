#content .pagination {
    padding: 30px 0px;
    margin-top:0px;
    text-align: center;
    
    li {
        padding:0;
        margin:0 2.5px;
        display: inline-block;
        width: 45px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        border-radius:$border-radius;
        font-size:20px;
        &.disabled {
            color: #F6F5F6;
        }
        
        &.active,
        a:hover {
            color: #fff;
            background:$color5;
            text-decoration: none; 
        }
        
        a {
            display: block;
            color: $color5;
            border-radius:$border-radius;
        }
        &:before,
        &:after{
            display:none;
        }
    }
}